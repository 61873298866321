import { apiSlice } from '../index';
import {
  CreditCheckCompanyRequest,
  CreditCheckCompanyResponse,
  CreditCheckPrivateRequest,
  CreditCheckPrivateResponse,
  DepositAccountRequest,
  TenantContactInfo,
  TenantsRequest,
  TenantsResponse
} from './types';

const tenantApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    companyCreditCheck: builder.mutation<
      CreditCheckCompanyResponse,
      CreditCheckCompanyRequest
    >({
      query: (payload) => ({
        url: 'v1/creditcheck/organization',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        params: payload,
        transformResponse: (response: CreditCheckCompanyResponse) => {
          return response.payload;
        }
      })
    }),
    privateCreditCheck: builder.mutation<
      CreditCheckPrivateResponse,
      CreditCheckPrivateRequest
    >({
      query: (payload) => ({
        url: 'v1/creditcheck/person',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        params: payload,
        transformResponse: (response: CreditCheckPrivateResponse) => {
          return response.payload;
        }
      })
    }),

    sendForm: builder.mutation({
      query: (payload) => ({
        url: 'SimpleWizard/TenantsSubmit',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: payload
      })
    }),

    getTenantsContractInfo: builder.query<TenantsResponse, TenantsRequest>({
      query: (payload) => ({
        url: 'SimpleTenantsWizard/Tenants',
        params: payload
      })
    }),
    getTenantContactInfo: builder.query<TenantContactInfo, number>({
      query: (id) => ({
        url: `v1/tenant/${id}/contact-person`
      })
    }),
    createDepositAccount: builder.mutation<any, DepositAccountRequest>({
      query: (payload) => ({
        url: `v1/depositaccounts`,
        method: 'POST',
        body: payload
      })
    })
  }),
  overrideExisting: false
});

export const {
  useCreateDepositAccountMutation,
  useGetTenantContactInfoQuery,
  useCompanyCreditCheckMutation,
  usePrivateCreditCheckMutation,
  useSendFormMutation,
  useGetTenantsContractInfoQuery
} = tenantApi;
